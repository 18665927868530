@import "styles/global.scss";

.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 85vh;
    background-color: #f4f4f9;
    padding: 20px;
}

.reset-password-box {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
}

.reset-password-box h2 {
    text-align: center;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-control {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.2s;
}

.form-control:focus {
    border-color: #007bff;
    outline: none;
}

.error-text {
    color: red;
    font-size: 0.875em;
}

.submit-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.submit-button:hover {
    background-color: #0056b3;
}

@media (max-width: 595px) {
    .reset-password-box {
        padding: 20px;
    }
}