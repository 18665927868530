.avatar {
  margin-bottom: 4px;
  background-color: $color-base !important;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.redux-form {
  width: 90%;
  margin-top: 4px;
}

.form-group {
  margin-bottom: 1.05rem;
}

.form-control {
  padding: 0.2rem 0.5rem;
  font-size: 14px;
}

.form-control:focus {
  border-color: $color-base;
  box-shadow: 0 0 0 0.1rem $color-base-hover;
}

.help-block {
  position: absolute;
  font-size: 10.5px;
  line-height: 1.5;
  color: $color-red;
}

input[type=text],
input[type=number],
input[type=email],
select {
  height: 29px;
}

input[type=text] {
  padding: 10px;
}

input[type=checkbox],
input[type=radio] {
  width: 13px;
}

input[type=radio]:focus {
  box-shadow: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.submit-button-container {
  margin-bottom: 10px;
}

.checkboxes label {
  display: block;
  float: left;
  padding-right: 10px;
  white-space: nowrap;
}

.checkboxes input {
  vertical-align: middle;
}

.checkboxes label span {
  vertical-align: middle;
}

.form-extra {
  color: $color-gray-light;
  text-align: center;
  font-size: 12px;
  justify-content: center;
}

.form-extra-link {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 24px;

  /* identical to box height */

  text-decoration-line: underline;
  color: #ff5c00;
  cursor: pointer;
}

.link-log-in {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 24px;
  color: #afafaf;
}

.form-extra-link:hover {
  color: $color-base-hover;
}

.forget {
  margin-top: 13px;
}

@media only screen and (max-width: 575px) {
  .form-extra.float-left,
  .form-extra.float-right {
    float: none !important;
  }
}
