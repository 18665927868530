@import "styles/global.scss";

.input-color {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  text-align: center;
}

.name {
  margin-left: 10px;
  //line-height: 150px;
}

.color {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: inline-block;
  background-size: contain;
  //margin-left: 15px;
}

.input-color strong {
  position: absolute;
  font-size: 9px;
  transform: translate(0, -22px);
  //margin-left: 16px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.float-xs-left {
  margin-right: 15px;
  margin-bottom: 26px;
  display: inline-block;
}

.float-xs-left-v2 {
  margin-right: 5px;
  margin-left: 5px;
  display: inline-block;
}

.tooltip-item {
  z-index: 1;
}

.tooltipBorder {
  border: 0.5px solid $color-gray-dark !important;
  border-radius: 10px;
}

.tooltipBorder.place-top::after,
.tooltipBorder.place-bottom::after {
  border-color: $color-gray-dark !important;
}

.tooltipBorder.place-right::after,
.tooltipBorder.place-left::after {
  border-color: transparent $color-gray-dark !important;
}

.__react_component_tooltip::before,
.__react_component_tooltip::after {
  content: none !important;
}
