@import "styles/global.scss";

.resume span {
  display: flex;
  justify-content: space-between;
}

.resume {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}
