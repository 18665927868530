@import "styles/global.scss";

.successful-purchase {
  margin: 170px 0 40px 0;
  flex-direction: column;

  p {
    color: $color-black-text;
    padding: 0 20px;
    max-width: 900px;
  }
}

.succesfull-orderId {
  font-weight: 700;
}

.successful-purchase-check {
  width: 212px;
  height: 212px;
  margin-bottom: 20px;
}

.successful-purchase-title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 32px;

  /* identical to box height, or 80% */

  text-align: center;
  color: #424242;
}

.successful-purchase-logo {
  margin: 0 0 20px 16px;
}

.top-nav-item-menu {
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  color: $color-white;
  text-align: center;
  cursor: pointer;
}

.top-nav-item-menu:hover {
  color: $color-gray-light;
}

.account-item-menu {
  text-align: center;
}

.button-reverse {
  border: 1px solid $color-base;
  border-radius: 10px;
  padding: 9px 49px;
  background-color: white;

  span {
    color: $color-base;
  }
}

.button-reverse:hover {
  background-color: $color-gray-light;
}

.button-come {
  border: 1px solid $color-base;
  border-radius: 10px;
  padding: 9px 70px;
  background-color: $color-base;

  span {
    color: white;
    text-decoration-line: underline;
  }
}

@media only screen and (max-width: 991px) {
  .successful-purchase-logo {
    margin-left: 0;
  }
}

@media only screen and (max-width: 1064px) {
  .successful-purchase {
    margin: 115px 0 40px 0;
  }
}
