@import "styles/global.scss";

.sub-categories-menu {
  font-size: 12px !important;
}

.products-menu-mobile-toggle {
  color: $color-white;
}

.products-menu-mobile .products-menu-mobile-toggle {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #181818;
  padding: 0 1.5em;
  position: absolute;
  top: 50%;
  right: 67px;
  transform: translate(0, -50%);
  margin-bottom: 0;
  cursor: pointer;
}

.products-menu-mobile input[type="checkbox"],
.products-menu-mobile .products-sub-menu-mobile {
  display: none;
}

.products-menu-mobile li {
  padding: 0 10px;
  line-height: 50px;
}

.products-menu-mobile-toggle:hover {
  color: $color-white;
}

.products-menu-mobile input[type="checkbox"]:checked + .products-sub-menu-mobile {
  display: block;
}

.text-menu {
  color: #fff !important;
}

.sin-productos {
  margin-top: 20px;
}

.icons-menu-mobile {
  justify-content: center;
  display: flex;
}
