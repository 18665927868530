@import "styles/global.scss";

.main-slider {
  width: 100%;
  //height: 100%;
}

.main-slider-img {
  width: 100%;
  //  height: 100%;
}

.new-arrivals-sorting {
  display: inline-block;
  border-radius: 3px;
  margin-bottom: 50px;
  overflow: visible;
  width: 100%;
}

.arrivals-grid-sorting {
  display: flex;
  justify-content: center;
  width: 100%;
}

.arrivals-grid-sorting li {
  display: flex;
  justify-content: center;
}

.grid-sorting-button {
  height: 42px;
  width: 195px;
  min-width: 102px;
  cursor: pointer;
  border: solid 1px #d3d3d6;
  border-radius: 3px;
  margin: 0;
  float: left;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  background: rgba(255, 92, 0, 0.5);
}

.grid-sorting-button:first-child {
  margin-left: 0;
  border-radius: 16px 0 0 0;
}

.grid-sorting-button:nth-child(3) {
  border-radius: 0 16px 0 0;
}

.grid-sorting-button.active {
  color: $color-white;
  background: $color-base;
}

.grid-sorting-button.active:hover {
  background: $color-base-hover !important;
}

.product-slider-container {
  margin-bottom: 74px;
  justify-content: center;
}

.line-home {
  //border: 2px solid #FF5C00;
  width: 100%;
  background: #ff5c00;
  height: 2px;
  margin-top: -1px;
}

.info-home-title {
  text-transform: uppercase;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 40px;
}

.home-products-carrousel {
  .rec-pagination {
    display: none !important;
  }

  .button.rec-dot {
    display: none !important;
  }

  .rec-dot_active {
    display: none !important;
  }
}

.info-home-title h2 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 36px;
  line-height: 32px;
  text-align: center;
  color: #ff5c00;
}

.dada {
  display: flex;
  flex-direction: column;
}

.MuiInput-underline.Mui-disabled::before {
  border-bottom: solid 1px $color-base;
}

.shop-by-category {
  margin: 25px 0;

  @media only screen and (min-width: 768px) {
    margin: 25px 0;
  }

  .categories {
    display: flex;
    flex-flow: wrap;
    gap: 10px;

    .category {
      background-color: black;
      width: calc(50% - 5px);
      cursor: pointer;
      overflow: hidden;

      @media only screen and (min-width: 768px) {
        width: calc(25% - 10px);
      }

      h4 {
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(0, 0, 0, 0.4);
      }

      img {
        width: 100%;
        display: block;
        transition: all ease 0.3s;
        height: 175px;
      }

      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .arrivals-grid-sorting li {
    //font-size: 20px;
  }

  .info-home-title h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .arrivals-grid-sorting {
    display: flex;
    flex-direction: column;
  }

  .arrivals-grid-sorting li {
    margin-bottom: 10px;
    width: auto;
    margin-left: -1px;
  }

  .grid-sorting-button:first-child {
    border-radius: 16px;
  }

  .grid-sorting-button:nth-child(2) {
    border-radius: 16px;
  }

  .grid-sorting-button:nth-child(3) {
    border-radius: 16px;
  }

  .info-home-title h2 {
    font-size: 28px;
  }
}
