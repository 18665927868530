@import "styles/global.scss";

.main-nav-container {
  width: 100%;
  background: $color-white;
}

.navbar-mobile {
  display: none;
}

.navbar {
  width: 100%;
  height: 100px;
  padding-left: 0;
  padding-right: 0;
}

.navbar-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hamburger-container {
  display: none;
}

.hamburger-container i {
  font-size: 24px;
  color: $color-black-text;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.search-container {
  display: none;
}

.search-container i {
  font-size: 24px;
  color: $color-black-text;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.navbar-menu {
  margin-bottom: 0;
}

.probando-nav li,
.navbar-menu li,
.navbar-categories li {
  display: inline-block;
}

.navbar-menu li a,
.navbar-menu li span {
  display: block;
  color: $color-black-text;
  font-size: 15px;
  font-weight: 500;
  padding: 20px;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
  cursor: pointer;
}

.navbar-menu li a.active,
.navbar-menu li span.active {
  color: $color-base;
}

.navbar-user {
  margin-bottom: 0;
}

.navbar-user li {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-user li div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40px;
  color: $color-black-text;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.navbar-icon {
  width: 40px;
  background: $color-gray-concrete;
  border-radius: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

.scroll-menu-arrow {
  padding: 10px;
  color: $color-white;
  font-size: 18px;
  cursor: pointer;
}

.scroll-menu-arrow--disabled {
  visibility: hidden;
}

.checkout-items {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -9px;
  left: 22px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $color-base;
  font-size: 12px;
  color: $color-white;
}

.probando-nav,
.navbar-categories {
  background-color: $color-base;
}

.probando-nav ul,
.navbar-categories ul {
  width: 100%;
  display: flex;
  justify-content: center;
}

.probando-nav li a,
.navbar-categories li a {
  display: block;
  color: $color-white;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  padding: 18px 20px;
}

.navbar-categories li a.active,
.navbar-categories li span.active {
  font-weight: 600;
}

.categories-navbar {
  display: block;
  color: $color-white;
  font-size: 16px;
  text-transform: uppercase;
}

.probando-nav {
  display: none;
  color: $color-white;
  font-size: 16px;
  text-transform: uppercase;
  padding: 18px 20px;
}

.nabvar-btn-search {
  position: absolute;
  bottom: 4px;

  /*
    position: relative;
  bottom: 35px;
  */
  right: 6px;
  min-width: 45px;
  height: 33px;
  width: 1px;
  padding: 0;
  border-radius: 24.25rem;
  float: right;
  background-color: #fff;
}

.icon-search-nav {
  font-size: 26px;
}

.nabvar-btn-search:hover {
  background-color: white;
}
*.icon-orange { color: #ff5c00; }

.autocomplete {
  background: #fff;
  border-radius: 32px;
  width: 100%;
}

.MuiInput-underline::before {
  display: none;
}

.MuiInput-underline.Mui-focused::after {
  display: none;
}

.MuiAutocomplete-input {
  padding: 6px 0 6px 15px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
  font-style: italic;
  font-size: 15px;
  align-items: center;
}

// ADENTRO DEL AUTOCOMPLETE
.MuiAutocomplete-listbox {
  li {
    border-bottom: solid 1px $color-base;
  }
}

.nabvar-search {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #ff5c00;
  border-radius: 32px;
  width: 350px;
  height: 40px;
  padding: 15px 20px;
}

.nabvar-searchContainer {
  display: block;
  align-items: center;
  justify-content: center;
  padding: 9px;
  width: 300px;
  z-index: 99;
}

.nabvar-searchContainer-mobile {
  display: block;
  align-items: center;
  justify-content: center;
  padding: 5px;
  //width: 300px;
  z-index: 99;
}

.MuiAutocomplete-popper ul {
  list-style-type: none;
  margin: 0;
  padding: 0 0 16px 0;
  display: block;
}

label + .MuiInput-formControl {
  margin-top: 10px;
}

.fs-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background: rgba(255, 255, 255, 0);
  pointer-events: none;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.pointer-active {
  pointer-events: auto;
}

.parent {
  background-color: #23282b;
  text-align: center;
  position: absolute;
  width: 100%;
  z-index: 5;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: ease-in-out 0.3s;
}

.categories-navbar:hover .parent {
  visibility: visible;
  opacity: 1;
  transition: ease-in-out 0.3s;
}

.parent-fijo {
  background-color: #23282b;
  text-align: center;
  position: absolute;
  width: 100%;
  z-index: 5;
  left: 0;
  visibility: visible;
  opacity: 1;
  transition: ease-in-out 0.3s;
}

.child {
  display: inline-block;
  font-size: medium;
  text-align: center;
  vertical-align: top;
}

.hamburger-menu {
  display: none;
  position: fixed;
  right: -400px;
  width: 400px;
  height: 100vh;
  background: rgba(255, 255, 255, 0.95);
  z-index: 10;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.hamburger-menu.active {
  right: 0;
  //left:0;
  overflow-y: scroll;
  background-color: #181818;
}

.hamburger-close {
  position: absolute;
  //top: 26px;
  //left: 10px;
  padding: 10px;
  z-index: 1;
}

.hamburger-close i {
  font-size: 24px;
  color: $color-black-text;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.hamburger-menu-content {
  padding-top: 100px;
  width: 100%;
  height: 100%;
  background-color: #181818;
}

.search-pointer-active {
  pointer-events: auto;
}

.search-menu {
  display: none;
  position: fixed;
  top: -30%;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  z-index: 10;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.search-menu.active {
  margin-top: 100px;
  top: 0;
  //overflow-y: scroll;
  background-color: $color-base;
}

.search-close {
  position: absolute;
  //top: 26px;
  //left: 10px;
  padding: 10px;
  z-index: 1;
}

.search-close i {
  font-size: 24px;
  color: $color-black-text;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.search-menu-content {
  //padding-top: 100px;
  width: 100%;
  height: 100%;
  background-color: $color-base;
}

.menu-top-nav {
  padding-left: 20px;
  overflow-y: auto;
  padding-right: 10px;
}

.menu-top-nav li {
  display: block;
  position: relative;
  //padding-right: 0;
  padding: 0 0 10px 0;
}

.account-li {
  padding: 0 !important;
}

.top-nav-item-favourite-menu {
  color: $color-white;
  padding: 3px 8px 3px 8px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid $color-white;
  border-radius: 16px;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  display: block;
}

.top-nav-item-favourite-menu-container {
  margin: auto;
  width: 36%;
  padding: 18px 0 0 0;
}

.top-nav-item-favourite-menu-container-login {
  margin: auto;
  width: 36%;
}

.image-heart-menu {
  width: 15px;
  margin: 0 7px 0 -1px;
}

.top-nav-item-menu {
  display: inline-block;
  vertical-align: top;
  text-decoration-line: underline;
  padding: 10px;
  color: $color-white;
  text-align: center;
  cursor: pointer;
}

.top-nav-item-menu:hover {
  color: $color-gray-light;
}

.account-item-menu {
  text-align: center;
}

.compras-menu {
  font-weight: 400 !important;
  height: 50px !important;
  line-height: 50px !important;
  font-size: 14px !important;
  text-transform: none !important;
}

.mi-cuenta-menu {
  line-height: 22.5px !important;
  text-transform: none !important;
  height: 0 !important;
}

.redes-menu-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  //padding: 0px 0px 8px 0px;
}

.redes-span-menu {
  color: $color-white;
}

.redes-mobile {
  margin-top: 10px;
}

.menu-top-nav li a {
  display: block;
  color: $color-white;
  font-weight: 500;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  text-transform: uppercase;
  //border-bottom: solid 1px $color-gray-iron;
  text-align: center;
  cursor: pointer;
}

.menu-top-nav li.active {
  display: block;
  visibility: visible;
  opacity: 1;
}

.menu-top-nav li a.active {
  color: $color-gray-light;
}

.menu-top-nav li a i {
  margin-left: 8px;
}

.hamburger-container:hover i {
  color: $color-gray-light;
}

.navbar-menu li a:hover,
.navbar-menu li a.active:hover {
  color: $color-gray-light;
}

.navbar-categories li a:hover {
  font-weight: 600;
  padding: 18px 18px;
}

.navbar-user li div:hover {
  color: $color-gray-light;
}

.hamburger-close:hover i {
  color: $color-gray-light;
}

.titles-mobile-menu {
  margin-top: 10px;
}

.menu-top-nav li a:hover,
.menu-top-nav li a.active:hover {
  color: $color-gray-light;
}

.arrow-prev {
  color: $color-white;
  margin-left: 10px;
}

.arrow-next {
  color: $color-white;
  margin-right: 10px;
}

@media only screen and (max-width: 1064px) {
  .main-nav-container {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  }

  .hamburger-container {
    display: block;
    padding: 10px;
  }

  .search-menu {
    display: block;
  }

  .hamburger-menu {
    display: block;
  }

  .probando-nav,
  .navbar-menu,
  .navbar-categories {
    display: none;
  }

  .navbar-mobile {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 1000;
    background-color: $color-white;
  }

  .navbar-text {
    display: none !important;
  }
}

@media only screen and (min-width: 1064px) {
  .MuiAutocomplete-popper {
    width: 500px !important;
    padding-top: 5px;

    /* position: absolute!important;

    height: 318px!important;
    top: 5px!important;
    left: 0px!important;
    right: 0px!important; */
  }
}

@media only screen and (max-width: 575px) {
  .navbar-user li {
    padding-left: 5px;
    padding-right: 5px;
  }

  .hamburger-menu {
    right: -100%;
    width: 100%;
    //left: -100%;
    //width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  /* .hamburger-close {
    //top: 14px;
    //left: 4px;
  }

  .hamburger-close i {
    font-size: 20px;
  } */

  /* .hamburger-menu-content {
    padding-top: 70px;
  } */

  .menu-top-nav {
    padding-left: 15px;
  }
}
