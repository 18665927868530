@import "styles/global.scss";

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  //margin-bottom: 20px;
}

.MuiCircularProgress-circle {
  stroke: $color-base !important;
}

.loading-text {
  font-size: 20px;
  color: $color-gray;
  margin-top: 20px;
}
