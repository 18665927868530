@import "styles/global.scss";

.product-item::after {
  display: block;
  position: absolute;
  top: 0;
  left: -1px;
  width: calc(100% + 1px);
  height: 100%;
  pointer-events: none;
  content: "";
  //border: solid 2px rgba(235, 235, 235, 0);
  border-radius: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.product-item:hover::after {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border: solid 0 rgba(235, 235, 235, 1);
  border-radius: 3px;
}

.product {
  width: 100%;
  //border-right: solid 1px $color-gray-concrete;
  border: 1px solid #ccc;
  cursor: pointer;
  background: #fff;
  //border: 0.5px solid #969696;
  //box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.product:hover {
  border: 0;
}

.product-image {
  width: 100% !important;
  padding: 15px !important;
}

.product-image img {
  height: 175px;
  width: 100%;
  border-radius: 20px;
}

.product-info {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.product-name a {
  color: $color-black-text;
  line-height: 20px;
}

.product-price {
  color: $color-base;
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 18px;
}

.product-title-name {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  height: 39px;
  text-align: center;
  color: $color-black-text;
  margin-bottom: 10px;
}

.product-price span {
  font-size: 0.9rem;
  margin-left: 10px;
  font-weight: 500;
  color: $color-gray-light;
  text-decoration: line-through;
}

.product-info .product-price {
  //font-size: 14px;
}

.product-quantity-selector {
  display: flex;
  justify-content: center;
  padding: 10px 0 10px 0;
}

.product-out-of-stock {
  text-align: center;
  color: $color-red;
}

.product-name:hover a {
  color: $color-gray-light;
}

.price-off {
  color: $color-white;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2px 15px !important;
  background: #ff5c00;
  //margin-left: 19px;
  //margin-bottom: 15px;
  border-radius: 0 8px 3px 8px;
}

.new-product {
  color: $color-white;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  background: #ff5c00;
  padding: 2px 15px !important;
  border-radius: 8px 0 8px 3px;
}

.product-image-icons {
  width: 100%;
}

.favorito-heart {
  position: absolute;
  background: #fff;
  //background: #FF5C00;
  border-radius: 0 0 0 8px;
  height: 31px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  text-align: center;
}

.image-heart-favourite-fill {
  color: $color-base;
  height: 20px !important;
  border-radius: 0 !important;
}

.image-heart-favourite-empty {
  color: $color-base;
  height: 20px !important;
  border-radius: 0 !important;
}

.heart-display {
  .image-heart-favourite-empty {
    display: block;
  }

  .image-heart-favourite-fill {
    display: none;
  }
}

.heart-display:hover {
  .image-heart-favourite-empty {
    display: none;
  }

  .image-heart-favourite-fill {
    display: block;
  }
}

.iconCard {
  display: inline-block;
  width: 26px;
  height: 23px;
  background-size: cover;
}

.product-button-details {
  background: #fff;
  border: 1px solid #ff5c00;
  width: 95%;
  color: #ff5c00;
}

.product-button-details:hover {
  background: #ff5c00;
  color: #fff;
}

@media only screen and (max-width: 576px) {
  /* .product {
    display: flex;
    flex-direction: row;
  }

  .product-image {
    width: 100%;
    padding: 10px;
  }

  .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }

  .product-quantity-selector {
    display: flex;
    justify-content: center;
    padding: 0;
    margin-top: 10px;
  } */
}

@media only screen and (max-width: 700px) {
  .product-price {
    font-size: 20px;
  }

  .product-title-name {
    font-size: 13px;
    height: 70px;
  }

  .product-image img {
    height: 125px;
  }

  .favorito-heart {
    height: 28px;
    width: 30px;
  }

  .image-heart-favourite {
    height: 20px !important;
  }
}
