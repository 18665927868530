@import "styles/global.scss";

.summary-items {
  width: 100%;
  font-size: 14px;
  height: 318px;
  margin-top: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.summary-item-info {
  padding: 0 30px;
}

.summary-item-image {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 75px;
  }
}

.separator-v2 {
  width: 100%;
  border-bottom: solid 3px $color-base;
  margin-top: 15px;
  margin-bottom: 15px;
}

.cart-summary-title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #ff5c00;
}

.summary-item-title {
  margin-right: 3px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;

  /* identical to box height, or 229% */

  text-decoration-line: underline;
  color: #424242;
}

.summary-item-title-v2 {
  margin-right: 3px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #424242;
}

.summary-item-description {
  color: $color-gray;
}

.summary-item-price {
  font-weight: 500;
  color: $color-base;
  margin-left: 1px;
  margin-right: 1px;
}

.summary-item-price span {
  font-size: 12px;
  margin-left: 10px;
  color: $color-gray-light;
  text-decoration: line-through;
}

.summary-subtotal {
  width: 100%;
  margin-top: 20px;
}

.span-subtotal {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 32px;
  text-align: right;
  color: rgba(81, 81, 81, 0.5);
}

.span-descuentos {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 32px;
  text-align: right;
  color: rgba(81, 81, 81, 0.5);
}

.span-costo {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 32px;
  text-align: left;
  color: rgba(81, 81, 81, 0.5);
}

.descuento-automatico {
  font-size: 15px;
}

@media only screen and (max-width: 1010px) {
  .span-descuentos {
    font-size: 15px;
  }

  .discount-span {
    font-size: 15px !important;
  }
}

.cart-summary-container {
  padding-left: 25px;
}
