@import "styles/global.scss";

.quantity {
  height: 35px;
}

.quantity-selector {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* width: 115px;
  height: 40px;
  border: solid 1px $color-gray-concrete; */
  margin-left: 6px;
}

.quantity-selector span {
  font-weight: 500;
  color: $color-white;
}

.quantity-selector-description input[type=number],
.quantity-selector input[type=number] {
  width: 40px;
  height: 30px;
  border: solid 1px $color-base;
  background: white;
  border-radius: 8px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 32px;
  color: #535353;
}

.quantity-selector-description {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  //width: 30px;
  height: 30px;
  //border: solid 1px $color-gray-concrete;
  //margin-left: 15px;
}

.quantity-selector-description span {
  font-weight: 500;
  color: $color-white;
}

.quantity-selector-description input[type=number] {
  width: 26px;
  height: 26px;
  text-align: center;
  font-size: 14px;
}

.plus,
.minus {
  padding-left: 6px;
  padding-right: 6px;
  height: 23px;
  padding-top: 1px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 15px;
  background: #ff5c00;
  cursor: pointer;
}

.plus-description,
.minus-description {
  padding-left: 5px;
  height: 21px;
  width: 20px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 15px;
  background: #ff5c00;
  cursor: pointer;
}

.plus:hover,
.minus:hover {
  color: $color-gray-light;
}

@media only screen and (max-width: 575px) {
  .quantity {
    height: auto;
    display: flex !important;
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 479px) {
  .quantity-text {
    margin-bottom: 5px;
  }

  .quantity-selector {
    margin-left: 0;
  }
}
