@import "styles/global.scss";

.modal {
  display: block;
  background: rgba(0, 0, 0, 0.6);
  overflow-y: auto;
}

.modal-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}

.vertical-alignment-helper {
  display: table;
  height: 100%;
  width: 100%;
}

.vertical-align-center {
  display: table-cell;
  vertical-align: middle;
}

.close {
  min-width: 0;
  line-height: 0.5;
  font-size: 24px;
}

.button-close-popup {
  min-width: 0;
  width: 0;
  line-height: 0.5;
  color: white;
  background: black;
}

.image-close {
  position: absolute;
  top: 17px;
  right: 10px;
  z-index: 9999;
}

.modal-content {
  padding: 15px;
  border: none;
  z-index: 1;
}

.titleCartModal {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 32px;
  text-align: center;
  color: #ff5c00;
  margin-left: 20px;
}

.titleModalContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.modal-cart {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close-button {
  float: right;

  img {
    height: 16px;
    margin-right: 10px;
  }
}

.titleDiv {
  height: 32px;
  padding-left: 20px;
  padding-right: 20px;
  background: #ff5c00;
  border-radius: 0 8px;
}

@media only screen and (max-width: 430px) {
  .modal-dialog {
    max-width: 400px !important;
    //margin: 10px auto;
  }

  .product-details-image-container {
    width: 185px;
  }
}

.modal-carrito { position: relative; width: auto; margin: 10px; pointer-events: none; }
.modal.fade .modal-carrito { transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; -webkit-transform: translate(0, -25%); transform: translate(0, -25%); }
.modal.show .modal-carrito { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
.modal-open .modal { overflow-x: hidden; overflow-y: auto; }

.modal-content-image {
  z-index: 1;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.carrousel-popup {
  max-width: 500px;
  max-height: 500px;
}

@media only screen and (min-width: 1100px) {
  .modal-carrito {
    max-width: 1024px !important;
    margin: 10px auto;
  }
}

@media only screen and (max-width: 1099px) {
  .modal-carrito {
    max-width: 800px !important;
    margin: 10px auto;
  }
}
