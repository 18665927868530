.modal-confirmCart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-confirmCart {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 655px;
  width: 100%;
  text-align: center;
}

.modal-confirmCart-content {
  margin-bottom: 20px;
}

.modal-confirmCart-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-confirm {
  background: #38a700;
  color: white;
}

.btn-empty {
  background: red;
  color: white;
}

.btn-close {
  background: #ff5c00;
  color: white;
}

@media (max-width: 655px) {
  .modal-confirmCart-actions {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .btn {
    width: 100%;
  }
}
