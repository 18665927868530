@import "styles/global.scss";

.download-purchase {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  /* identical to box height */
  text-decoration-line: underline;
  color: #ff5c00;
  display: flex;
  cursor: pointer;
}

.title-purchase {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #424242;
}

.title-purchase-history {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #9b9b9b;
}

.price-purchase {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  color: #ff5c00;
}

.purchase-mobile-container {
  margin-top: 20px;
}

.button-purcharse-mobile {
  width: 100%;
}

.purchase-mobile-title {
  width: 70px;
  margin-left: 10px;
  margin-right: 40px;
}

.product-container-purchase {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 11px;
  text-align: center;
}

.items-container-purchase {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
}

.purchase-mobile-number {
  margin-right: 40px;
}

.purchase-items-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
}

@media only screen and (max-width: 281px) {
  .purchase-mobile-title {
    width: 70px;
    margin-left: 0;
    margin-right: 10px;
  }
}
