@import "styles/global.scss";

#google-map {
  width: 100%;
  height: 50vh;
  margin-bottom: 50px;
}

.map-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.info-contact-title {
  text-align: center;
}

.info-contact-title h2 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 36px;
  line-height: 32px;
  text-align: center;
  color: #ff5c00;
}

.info-contact {
  margin-top: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
}

#map {
  width: 100%;
  height: 100%;
  border: solid 1px $color-base;
}

.text-area {
  height: 150px;
  resize: none;
}

.input-search-contact {
  height: 35px !important;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #c6c6c6;
}

.contact-contents {
  text-align: left;
  width: 328px;
  //height: 324px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 55px;
  background: #fff;
  border: 1px solid #ff5c00;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.get-in-touch-contents {
  //margin-top: 15px;
}

.height-message {
  height: 126px !important;
}

.contact-contents span,
.contact-contents a,
.get-in-touch-contents p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: $color-gray-dark;
}

.contact-contents div {
  margin-top: 10px;
  display: grid;
}

.contact-loading {
  margin-top: 3rem;
}

.contact-contents div span:first-of-type {
  color: $color-black;
  font-weight: bold;
}

.redux-form-contact {
  width: 100%;
  margin-top: 4px;
}

.icons-contact-container {
  justify-content: center;
  display: flex;
  margin-top: 50px;
}

.icon-contact {
  margin-right: 5px;
  margin-left: 5px;
}

.tiktok-contact {
  width: 30px;
  height: 30px;
}

@media only screen and (max-width: 991px) {
  #google-map {
    height: 60vh;
  }

  .get-in-touch-col,
  .get-in-touch-contents {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .map-container {
    box-shadow: 0 15px 39px rgba(0, 0, 0, 0.15);
  }

  .contact-contents p,
  .get-in-touch-contents p {
    font-size: 13px;
  }

  .contact-contents div {
    margin-top: 13px;
  }
}
