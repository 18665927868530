@import "styles/global.scss";

.product-image {
  display: flex;
  justify-content: center;
  width: 100%;
}

.product-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 5px;
}

.product-details-image-container {
  margin: 0 auto;
  //padding: 20px 0;
  width: 250px;
}

.product-details-image {
  width: fit-content;
}

.product-details-section {
  margin-top: 10px;

  .quantity-selector {
    margin-left: 0;
  }

  .minus {
    margin-left: 0;
  }

  @media only screen and (max-width: 575px) {
    .quantity {
      flex-direction: row !important;
    }
  }
}

.product-details-name {
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  //text-align: center;
  color: #424242;
}

.product-details-copy-icon {
  margin-left: 10px;
  font-size: 15px;
  cursor: pointer;
}

.product-details-price {
  color: #ff532b;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
}

.product-details-price span {
  font-size: 30px;
  margin-left: 20px;
  color: $color-gray-light;
  text-decoration: line-through;
}

.product-details-description {
  display: block;
  margin: 0 0 15px;
  //background-color: $color-gray-concrete;
  //border: 1px solid $color-gray-iron;
  //border-radius: 4px;
  pre {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    color: #424242;
    margin-bottom: 0;
  }
}

.title-product-detail {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #424242;
}

.cantidad-product-detail {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #424242;
  margin-bottom: 10px;
  //margin-left: 21px;
  display: block;
}

.product-details-out-of-stock {
  text-align: center;
  color: $color-red;
  font-size: 25px;
}

.img-holder {
  position: relative;
}

.img-holder .linkphotos {
  position: absolute;
  width: 10%;
  font-size: 10px;
  bottom: 10px; /* your button position */
  right: 90px; /* your button position */
}

.options-items {
  margin-top: 20px;
}

.quantity-card {
  justify-content: center;
  //margin-top: auto;
  margin-bottom: 30px;
}

.selector-card {
  display: flex;
  //justify-content: center;
  margin-left: -12px;
}

.button-fav {
  border: 0 solid $color-base;
  //border-radius: 10px;
  padding: 9px 70px;
  background-color: white;

  span {
    color: $color-base;
  }
}

.button-fav:hover {
  background-color: $color-base;

  span {
    color: white;
  }
}

.fav-container {
  display: flex;
  background: #fff;
  justify-content: center;
  position: absolute;
  border-radius: 0 0 0 8px;
  top: 0;
  z-index: 3;
  right: 0;
}

.product-fav {
  padding: 9px 10px 9px 10px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ff5c00;
  box-sizing: border-box;

  img {
    width: 25px;
    //margin: 0px 5px 0px -1px;
  }
}

@media only screen and (max-width: 991px) {
  .product-details-image-container {
    width: 185px;
  }
}
