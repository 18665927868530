@import "styles/global.scss";

.footer {
  background-color: $color-black-text;
  color: $color-white;
  font-size: 14px;
}

.footer-column {
  display: flex;
  flex-direction: column;
}

.footer-top {
  padding: 60px 0;
}

.footer-title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
}

.footer-column .logo-container a span:first-child,
.footer-column .logo-container a span:last-child {
  color: $color-white;
}

.footer-column .logo-container a:hover span {
  color: $color-gray-light;
}

.footer-column .title {
  font-size: 16px;
  font-weight: bold;
  position: relative;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: $color-gray-light;
}

.button-map {
  margin-top: auto;
}

.info-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.redes-footer {
  margin-right: auto;
  margin-left: 20px;
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.childs-categories {
  margin-top: 10px;
}

.child-categories {
  display: inline-block;
  font-size: medium;
  text-align: center;
  vertical-align: top;
  margin-right: 20px;

  a {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 185.4%;

    /* or 26px */

    text-decoration-line: underline;
    color: #fff;
  }
}

.subtitle-footer {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #fff !important;
}

.social-links a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: $color-base;
  color: $color-white;
  font-size: 18px;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  transition: 0.3s;
}

.copyright-wrap {
  border-top: 1px solid $color-base;
  justify-content: center;
}

.copyright a {
  color: $color-base;
}

.image-footer {
  width: 100%;
}

.footer-column ul li a,
.copyright a:hover {
  color: $color-gray-light;
}

.social-links a:hover {
  background-color: $color-gray-light;
  color: $color-white;
  text-decoration: none;
}

.footer-column ul li a:hover {
  color: $color-base;
}

.image-redes-footer {
  margin-right: 10px;
}

@media only screen and (max-width: 991px) {
  .footer-top {
    padding-top: 30px;
    padding-bottom: 0;
  }

  .button-map {
    margin-top: 20px;
  }

  .footer-column {
    padding-bottom: 30px;
    max-width: 410px;
  }
}

@media only screen and (max-width: 767px) {
  .info-footer {
    flex-direction: column;
  }

  .redes-footer {
    margin-left: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .social-links {
    justify-content: flex-start;
  }
}
