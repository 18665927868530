@import "styles/global.scss";

.logo-container {
  //margin-right: 16px;
}

.logo-image {
  width: 128px;
}

.logo-loading {
  margin-top: 20px;
}

//--------------

.logo-fade {
  animation: fade 1s forwards;
  -webkit-animation: fade 1s forwards;
  -moz-animation: fade 1s forwards;
  -o-animation: fade 1s forwards;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
}

@-o-keyframes fade {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fade {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@-webkit-keyframes fade {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@-moz-keyframes fade {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@media only screen and (max-width: 991px) {
  .logo-container {
    margin-right: 0;
  }
}
