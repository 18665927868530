@import "styles/global.scss";

.top-nav {
  width: 100%;
  background-color: $color-white;
}

.top-nav-content {
  display: flex;
  align-items: center;
  font-size: 12px;
  //padding: 7px 0px 0px 0px;
  padding: 10px 0;
}

.top-nav-user-actions {
  text-align: right;
}

.top-nav-border-right {
  border-right: 1px solid $color-base;
}

.top-nav-item {
  color: $color-base;
  padding: 0 10px;
  cursor: pointer;
}

.top-nav-item-middle {
  color: black;
  padding: 0 10px;
  cursor: pointer;
}

.top-nav-item-favourite {
  color: $color-base;
  padding: 9px 10px 9px 10px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid $color-base;
  border-radius: 16px;
  font-weight: 700;
  font-size: 12px;
}

.top-nav-item:hover {
  color: $color-gray-iron;
}

.image-heart {
  width: 15px;
  margin: 0 5px 0 -1px;
}

.image-social {
  width: 18px;
}

@media only screen and (max-width: 767px) {
  .top-nav-user-actions,
  .top-nav-info {
    text-align: center;
    margin: 5px 0;
  }
}

@media only screen and (max-width: 1064px) {
  .top-nav {
    display: none;
  }
}

@media only screen and (max-width: 1400px) {
  .top-nav-item-favourite {
    color: $color-base;
    padding: 5px 5px 5px 5px;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid $color-base;
    border-radius: 16px;
    font-weight: 600;
    font-size: 10px;
  }

  .image-heart {
    width: 10px;
  }
}
