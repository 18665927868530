@import "styles/global.scss";

.registerDiv {
  height: 32px;
  width: 144px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  background: #ff5c00;
  border-radius: 0 8px;
}

.registerTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  text-align: center;
  color: #fff;
}

.passwordTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  text-align: center;
  color: $color-base;
}

.caca {
  width: 148px !important;
  height: 46px !important;
}

.inpute-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 16px;
  gap: 10px;
  height: 46px !important;
  background: #fff;
  border: 1px solid #cacaca;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  //color: #C6C6C6;
}
