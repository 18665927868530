@import "styles/global.scss";

.color-negro {
  color: #6f6e6e;
}

.frequent-title {
  width: 100%;
  padding: 30px;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
}

.frequent-title span {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 33px;
  //line-height: 32px;
  text-align: center;
  color: #ff5c00;
}

.question {
  text-align: center;
  padding: 1rem;
}

.question h3 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #000;
}

.question p {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #000;
}

.questions-container {
  margin-top: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.link-question {
  color: #ff5c00;
}
