@import "styles/global.scss";

.checkout-page {
  margin-top: 166px;
}

.minimum-purchase {
  margin-top: 30px;
  color: $color-red;
}

.checkout-place-container {
  text-align: center;

  span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #424242;
  }
}

.checkout-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.button-checkout-selected {
  background: $color-base;
  border: 1px solid rgba(255, 92, 0, 0.5);
  height: 40px;
  width: 328px;
  color: $color-base;

  /* min-width:200px; */
  border-radius: 10px;

  span {
    color: $color-white;
  }
}

.button-checkout {
  background: #fff;
  border: 1px solid rgba(255, 92, 0, 0.5);
  height: 40px;
  width: 328px;
  color: $color-base;

  /* min-width:200px; */
  border-radius: 10px;

  span {
    color: $color-base;
  }
}

.button-checkout:hover {
  span {
    color: $color-white;
  }
}

.checkout-form {
  margin-top: 30px;
}

@media only screen and (max-width: 1116px) {
  .mg-bot {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1064px) {
  .checkout-page {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-cart-summary {
    margin-top: 30px;
  }

  .checkout-place-container {
    margin-top: 30px;
  }
}
