@import "styles/global.scss";

.sidebar-categories li {
  border-bottom: 1px solid lightgrey;
  width: 137px;
}

.sidebar-categories li a {
  text-transform: uppercase;
  text-decoration: none;
  padding: 6px 0;
  width: 100%;
  margin: 0.3125rem 0 0;
  font-size: 14px;
  line-height: 40px;
  font-weight: 500;
  color: #727272;
}

.paginated {
  justify-content: center;
}

.products-loading {
  width: 100%;
  margin-top: 60px;
}

.main-content {
  float: left;
  width: calc(100% - 200px);
  //padding-left: 34px;

  .product-image img {
    height: 140px;
  }
}

.sidebar {
  float: left;
  width: 200px;
  padding-right: 17px;
}

.sidebar-section {
  padding-bottom: 35px;
  margin-bottom: 50px;
  border-bottom: solid 1px $color-gray-concrete;
}

.sidebar-title h5 {
  font-size: 18px;
  margin-bottom: 21px;
}

.sidebar-categories li a.active {
  color: $color-base;
}

.filter-price-button {
  width: 100%;
  height: 30px;
  background: $color-black-text;
  text-align: center;
  margin-top: 25px;
}

.offers {
  margin-left: 5px;
  text-align: center;
  color: $color-black-text;
  height: 30px;
}

.ordenar {
  text-align: left;
  margin-right: 10px;
  color: $color-black-text;
  align-self: center;
  font-size: 14px;
  font-weight: 500;
}

.MuiSlider-root {
  color: #ff532b !important;
}

.selectOrdenar {
  width: 80px;
  margin-left: 5px;
}

.filterOrdenar {
  //margin-top: 0px;
  //padding-right: 55px;
  //display: flex;
  //flex-direction: row;
  width: 240px;
}

.MuiPagination-ul {
  justify-content: center;
}

.MuiPaginationItem-root {
  color: $color-base !important;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: $color-base !important;
  color: $color-white !important;
  border-color: $color-base;
}

.filter-price-button:hover {
  background: $color-black-light;
}

.filter-price {
  display: flex;
  flex-direction: column;
  margin-right: 45px;
  margin-left: 15px;
  width: 225px;
}

.sidebar-categories li a:hover {
  color: $color-gray-light;
}

.sidebar-categories li a.active:hover {
  color: $color-base-hover;
}

.show-filter-categorys {
  display: 'block' !important;
}

.show-filter-categorys-secondary {
  display: none !important;
}

.filters-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  span {
    margin-right: 5px;
    font-size: 14px;
    font-weight: 500;
    text-align: end;
  }
}

.products-title {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px;
  background: #f8f8f8;
  margin-bottom: 30px;
}

.products-title span {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 33px;
  //line-height: 32px;
  text-align: center;
  color: #ff5c00;
}

.offers-title {
  width: 100%;
  padding: 30px;
  margin-bottom: 30px;

  h6 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 100;
    font-size: 36px;
    line-height: 32px;
    text-align: center;
    color: #ff5c00;
  }
}

.offers-products {
  margin-top: 100px;
  margin-bottom: 50px;
  padding-left: 10%;
  padding-right: 10%;
}

.rec-pagination {
  button {
    min-width: 10px;
  }
}

button.rec-dot {
  background-color: $color-base !important;
  opacity: 0.5;
  box-shadow: none !important;
}

.rec-dot_active {
  opacity: 1 !important;
}

button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus {
  opacity: 1;
}

button.rec-arrow {
  background-color: $color-base !important;
  color: $color-white;
}

.rec.rec-arrow-left:hover:enabled,
.rec.rec-arrow-right:hover:enabled {
  opacity: 0.5;
}

.carrito-empty {
  height: 350px;
}

.no-realizo {
  display: flex;
  justify-content: center;

  span {
    margin-top: 150px;
    font-size: 20px;
    color: $color-gray-iron;
  }
}

.no-realizo-v2 {
  display: flex;
  justify-content: center;

  span {
    font-size: 20px;
    color: $color-gray-light;
  }
}

.mg-resp-carrousel {
  padding-bottom: 25px;
}

@media only screen and (max-width: 1199px) {
  .sidebar {
    float: none;
    width: 100%;
    padding-right: 0;
  }

  .main-content {
    float: none;
    width: 100%;
    padding-left: 0;
  }

  .paginated {
    justify-content: center;
  }
}

@media (max-width: 600px) {
  button.rec-dot {
    display: none !important;
  }

  .rec-dot_active {
    display: none !important;
  }

  button.rec-dot:hover,
  button.rec-dot:active,
  button.rec-dot:focus {
    display: none !important;
  }

  .show-filter-categorys {
    display: none !important;
  }

  .show-filter-categorys-secondary {
    display: block !important;
  }
}

@media only screen and (max-width: 500px) {
  .offers-products {
    padding-left: 5%;
    padding-right: 5%;
  }

  button.rec-arrow {
    height: 30px;
    min-width: 30px;
    width: 30px;
    font-size: 1.3em;
  }

  .resp-carrousel {
    padding-right: 5px;
    padding-left: 5px;
  }
}
