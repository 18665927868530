@charset "utf-8";

/***********
1. Fonts
***********/

@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,900");

/*********************************
2. Body and some general stuff
*********************************/

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family-text;
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  background: $color-white;
  color: $color-black-text;
}

div {
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

ul {
  list-style: none;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: $color-black-shadow;
  text-shadow: $color-black-shadow;
}

p {
  font-family: $font-family-text;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 500;
  color: $color-gray-light;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: $color-black-shadow;
  text-shadow: $color-black-shadow;
}

p a {
  display: inline;
  position: relative;
  color: inherit;
  border-bottom: solid 2px $color-pink-light;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

a:hover,
a:visited,
a:active,
a:link {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: $color-black-shadow;
  text-shadow: $color-black-shadow;
}

p a:active {
  position: relative;
  color: $color-base;
}

p a:hover {
  color: $color-base;
  background: $color-pink-light;
}

p a:hover::after {
  opacity: 0.2;
}

::selection {
  background: $color-pink-light;
  color: $color-base;
}

p::selection {
  background: $color-pink-light;
}

h1 {
  font-size: 72px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-text;
  color: $color-black-text;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: $color-black-shadow;
  text-shadow: $color-black-shadow;
}

::-webkit-input-placeholder {
  font-size: 14px !important;
  font-weight: 500;
  color: $color-gray !important;
}

/* Firefox 19+ */
::-moz-placeholder {
  font-size: 14px !important;
  font-weight: 500;
  color: $color-gray !important;
}

/* older Firefox */
:-moz-placeholder {
  font-size: 14px !important;
  font-weight: 500;
  color: $color-gray !important;
}

:-ms-input-placeholder {
  font-size: 14px !important;
  font-weight: 500;
  color: $color-gray !important;
}

section {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.clear {
  clear: both;
}

.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
}

.clearfix::after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

.trans-200 {
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.trans-300 {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.trans-400 {
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.trans-500 {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.fill-height {
  height: 100%;
}

.separator {
  width: 100%;
  border-bottom: solid 1px $color-base;
  margin-top: 15px;
  margin-bottom: 15px;
}

/* Header */
.header {
  width: 100%;
  background: $color-white;
  position: fixed;
  z-index: 5;
}

.col-not-separated {
  padding-right: 0;
  padding-left: 0;
  margin-top: 15px;
}

.col-product {
  position: relative;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin: 6px;
}

.col-product-carrousel {
  position: relative;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin: 0;
  background: rgb(249 249 249);
  max-width: 269.396px;
}

.carrousel-wrapper {
  padding: 0 36px;
  max-width: 1500;
  margin: 0 auto;
}

.button-carrousel {
  background-color: $color-base;
  color: white;
  display: flex;
  font-size: 25px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25), 0 8px 8px rgba(0, 0, 0, 0.25);
  transition: filter 0.2s ease-in-out;
}

.button-carrousel:hover {
  opacity: 0.8;
}

.margin-product-carousel {
  margin: 6px !important;
}

.margin-product {
  margin: 3px 6px 15px !important;
  max-width: 200.396px;
  width: 44%;
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.carousel .slide {
  background-color: $color-white !important;
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 95%;
  }

  .col-product {
    max-width: 16%;
  }

  .col-product-carrousel {
    max-width: 100%;
  }

  .col-products {
    max-width: 22%;
  }
}

@media only screen and (max-width: 1400px) {
  .container {
    max-width: 95%;
  }

  .col-product {
    max-width: 16%;
  }

  .col-products {
    max-width: 30%;
  }
}

@media only screen and (max-width: 1199px) {
  .container {
    max-width: 95%;
  }

  .col-product {
    max-width: 25%;
  }

  .col-products {
    max-width: 25%;
  }
}

@media only screen and (max-width: 991px) {
  .container {
    max-width: 95%;
  }

  .col-product {
    max-width: 28.333333%;
    //max-width: 29%;
  }

  .col-products {
    max-width: 33.333333%;
  }
}

@media only screen and (max-width: 960px) {
  .container {
    max-width: 95%;
  }

  .col-product {
    //max-width: 30%;
    //max-width: 35%;
  }

  .col-products {
    max-width: 36%;
  }
}

@media only screen and (max-width: 780px) {
  .col-products {
    max-width: 40%;
  }

  .col-product {
    //max-width: 50%;
    max-width: 40%;
  }

  .margin-product {
    //margin: 0px !important;
  }
}

@media only screen and (max-width: 595px) {
  .col-products {
    max-width: 40%;
  }

  .col-product {
    //max-width: 50%;
    max-width: 40%;
  }

  .margin-product {
    max-width: 155.396px;
  }
}

@media only screen and (max-width: 445px) {
  .col-product {
    max-width: 41%;
    margin: 10px;
  }

  .product-title-name {
    line-height: 15px;
  }

  .product-price {
    line-height: 13px;
  }

  .col-products {
    max-width: 60%;
  }

  .button-carrousel {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 405px) {
  .col-product {
    max-width: 44%;
  }

  .col-products {
    max-width: 60%;
  }
}

@media only screen and (max-width: 369px) {
  .col-product {
    max-width: 70%;
  }
}

@media only screen and (max-width: 375px) {
  .margin-product {
    width: 80% !important;
  }
}
