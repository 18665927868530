@import "styles/global.scss";

.radio-button-info {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.radio-button-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.radio-button-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
  font-size: 12px;
}

.radio-active {
  background-color: $color-gray-concrete;
}

.truck-image {
  width: 100px;
  height: 85px;
}

.link {
  width: fit-content;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 128.5%;
  text-decoration-line: underline !important;
  color: #ff5c00;
}

@media only screen and (max-width: 575px) {
  .radio-button-info {
    flex-direction: column;
  }
}
