.super-container {
  width: 100%;
  overflow: hidden;
}

.container-page {
  margin-top: 132px; /* Add a bottom margin to avoid content overlay */
}

@media only screen and (max-width: 1064px) {
  .container-page {
    margin-top: 100px; /* Add a bottom margin to avoid content overlay */
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:ital,wght@1,100&display=swap');
