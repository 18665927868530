@import "styles/global.scss";

.notification-cart {
  position: fixed;
  right: 80px;
  bottom: 91px;
  z-index: -1;
  background-color: rgb(255, 92, 0);
  width: 266px;
  height: 53px;
  text-align: center;
  padding: 14px 23px 14px 5px;
  //display: none;
  transform: translate3d(-20px, 0, 0); /* ADDED - start x position as -50px */
  transition: 0.25s all ease-in-out;
  opacity: 0;
  overflow: hidden;
}

.notification-cart.active {
  opacity: 1;
  height: 53px;
  z-index: 99;
  transform: translate3d(0, 0, 0);
}

.notification-cart::before {
  content: "";
  display: block;
  width: 50px;
  height: 60px;
  border-radius: 50px;
  position: absolute;
  right: -36px;
  background: white;

  /* opacity: 0.5; */
  top: 50%;
  transform: translateY(-50%);
}

.notification-span {
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}

.cartCircle {
  cursor: pointer;
  position: fixed;
  right: 15px;
  bottom: 85px;
  z-index: 99;
  background-color: rgba(255, 92, 0, 1);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  opacity: 1;
  border-radius: 50%;
}

.cartCircle-image {
  width: 35px;
  height: 35px;
  margin: 15px 15px;
}

.cartCircle:hover {
  opacity: 0.8;
}

.checkout-cirlce {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 9px;
  left: 35px;
  width: 20px;

  /* height: 20px; */
  border-radius: 50%;
  background: #fff;
  font-size: 12px;
  color: #ff532b;
  border-width: 6px;
}
